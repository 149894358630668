<template>
    <div class="timer_wrapper">
        <div class="puzzle-timer" v-for='(part, i) in timer' :key="i">
            <div class="timer_parts" v-if="part.value !== false">
                <div class="puzzle-t_value">{{ part.value }}</div>
                <div class="puzzle-t_unit">{{ part.unit }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FormattedTimer',
    props: {
        entry : { type: Number } // In seconds
    },
    computed: {
        timer() {
            let u = 60
            let t = this.entry
            let m = t/u >= 1 ? Math.floor(t/u) : false //Minutes
            let h = m/u >= 1 ? Math.floor(m/u) : false // Hours
            return [
                { unit: 'Hrs', value: h },
                { unit: 'Min',  value: m >= u ? m%u : m },
                { unit: 'Sec',  value: t >= u ? t%u : t }
            ]
        }
    }
}
</script>